import { DateTime } from 'luxon';
import hitApi from '../../../../api';
import { rejectFailedRequest } from '../../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { APIResponse } from '../../../../types/api_response.model';

export interface SharedResourceUserWithAccess {
  id: string;
  email: string;
  dateShared: string;
}

export interface MappedSharedResourceUserWithAccess {
  id: string;
  email: string;
  dateSharedText: string;
  avatarLetter: string;
}

const getSharedWithUsers = async (
  id: string,
): Promise<MappedSharedResourceUserWithAccess[]> => {
  const response = (await hitApi.get(`/shared-sbom/users/${id}`)) as APIResponse<
    SharedResourceUserWithAccess[]
  >;

  const { data } = await rejectFailedRequest(response);

  return data.map((user) => ({
    email: user.email,
    avatarLetter: user.email[0].toUpperCase(),
    dateSharedText: DateTime.fromISO(user.dateShared).toFormat('MMMM dd, yyyy'),
    id: user.id,
  }));
};

export interface ShareResourcePayload {
  emails: string[];
  resourcesIds: string[];
  resourcesType: 'asset';
  sendNotification: boolean;
  resourceFormat: 'json';
}

const shareResource = async (payload: ShareResourcePayload) => {
  const response = (await hitApi.post('/share', true, payload)) as APIResponse<{}>;
  const { data } = await rejectFailedRequest(response);

  return data;
};

export interface DeleteAccesToResourceParams {
  userId: string;
  resourceId: string;
}

const deleteAccesToResource = async ({
  userId,
  resourceId,
}: DeleteAccesToResourceParams) => {
  const response = (await hitApi.delete(`/share/delete/${resourceId}`, true, {
    userId: userId,
  })) as APIResponse<{}>;
  const { data } = await rejectFailedRequest(response);

  return data;
};

export const ShareResourceApi = {
  getSharedWithUsers,
  shareResource,
  deleteAccesToResource,
};
