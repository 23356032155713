import { ActionIcon, Menu } from '@mantine/core';
import Icon from '../../../components/Icon';
import styles from './UserMenu.module.scss';

export const UserMenu = ({
  texts,
  onLogOut,
  goToSettings,
}: {
  onLogOut: () => void;
  goToSettings: () => void;
  texts: {
    logOut: string;
    settings: string;
  };
}) => {
  return (
    <Menu width={210}>
      <Menu.Target>
        <ActionIcon variant="transparent" className={styles.button}>
          <Icon icon="user" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<Icon icon="circle-user" />} onClick={goToSettings}>
          {texts.settings}
        </Menu.Item>
        <Menu.Item icon={<Icon icon="sign-out-alt" />} onClick={onLogOut}>
          {texts.logOut}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
