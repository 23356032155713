import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { ManifestBubbleChartDataType } from '../../components/ManifestCharts/ScatterBubble/types';

export const useFetchBubbleChartData = () => {
  const filters = [{ field: 'assetActive', value: [true] }];
  const queryParams = new URLSearchParams({ filters: JSON.stringify(filters) });

  const fetchUrl = `vulnerabilities/summary-graph/?limit=500&severityFloor=5&sort={"cvss3BaseScore":-1,"cvss2BaseScore":-1}&${queryParams.toString()}`;

  return useQuery({
    queryKey: ['vulnBubbleChartData', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (fetchData) => {
      if (fetchData && fetchData?.success && fetchData?.data) {
        const vulnsBubbleCriticalData: ManifestBubbleChartDataType[] = [];
        const vulnsBubbleHighData: ManifestBubbleChartDataType[] = [];
        const vulnsBubbleMediumData: ManifestBubbleChartDataType[] = [];
        const vulnsBubbleLowData: ManifestBubbleChartDataType[] = [];

        for (const singleVuln of fetchData?.data) {
          const thisSeverity = `${
            singleVuln.cvss3BaseSeverity || singleVuln?.cvss2BaseSeverity || 'unknown'
          }`
            .trim()
            .toLowerCase();

          let epssScorePercentage = -1;
          if (singleVuln?.epssScore) {
            epssScorePercentage = Math.round(singleVuln.epssScore * 10000) / 100;
          }

          const dataArray: ManifestBubbleChartDataType = {
            x: singleVuln?.cvss3BaseScore || singleVuln?.cvss2BaseScore || 0, // -1 in case of unexpected behavior
            y: epssScorePercentage, // -1 in case of unexpected behavior
            z: singleVuln?.countAffectedAssets, // Size, determined by number of affected assets
            context: singleVuln,
          };

          switch (thisSeverity) {
            case 'critical':
              vulnsBubbleCriticalData.push(dataArray);
              break;
            case 'high':
              vulnsBubbleHighData.push(dataArray);
              break;
            case 'medium':
              vulnsBubbleMediumData.push(dataArray);
              break;
            case 'low':
              vulnsBubbleLowData.push(dataArray);
              break;
            default:
              console.warn('Skipping assignment for an unknown CVSS base severity!');
              break;
          }
        }

        return {
          vulnsBubbleCriticalData,
          vulnsBubbleHighData,
          vulnsBubbleMediumData,
          vulnsBubbleLowData,
        };
      }
    },
  });
};
