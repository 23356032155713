import { DateTime } from 'luxon';
import capitalizeWords from '../../../../lib/capitalizeWords';
import {
  MappedSharedAsset,
  SharedSbomsApiResponse,
} from '../types/sharedSboms.interface';

export interface MapSharedSbomsTexts {
  unknownType: string;
}

export const mapSharedSboms = (
  data: SharedSbomsApiResponse['data'],
  texts?: MapSharedSbomsTexts,
): MappedSharedAsset[] => {
  return data.map((element) => ({
    id: element._id?.toString() || '',
    name: element.name || element.packageUrlNoVersion || element.coordinates || '',
    dateSharedText: DateTime.fromISO(`${element.dateCreated}`).toRelative() || '',
    type: element.type ? capitalizeWords(element.type) : texts?.unknownType || '',
    version: element.version,
  }));
};
