import { Box, Checkbox, Divider, Flex, Radio, Skeleton, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { captureException, captureMessage } from '@sentry/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarComponent } from '../../../components/AvatarComponent/AvatarComponent';
import ManifestModal from '../../../components/ManifestModal/ManifestModal';
import { RemovableListItem } from '../../../components/RemovableListItem/RemovableListItem';
import { useNotifications } from '../../../hooks/utils/useNotifications';
import EmailInput from '../../UserSettings/SbomUploadAlerts/components/EmailInput/EmailInput';
import { SHARED_ELEMENTS_USERS_WITH_ACCESS_STUB } from '../__stubs__/shareResourceUsersWithAccess.stub';
import {
  MappedSharedResourceUserWithAccess,
  ShareResourcePayload,
} from '../api/shareResource/shareResource.api';
import { useDeleteSharedResourceUsers } from '../hooks/useDeleteSharedResourceUsers/useDeleteSharedResourceUsers';
import { useFetchSharedResourceUsers } from '../hooks/useFetchSharedResourceUsers/useFetchSharedResourceUsers';
import { usePostSharedResourceUsers } from '../hooks/usePostSharedResourceUsers/usePostSharedResourceUsers';
import styles from './ShareSbomModal.module.scss';

interface ShareSbomModalProps {
  onClose: () => void;
  resourcesIds: string[];
}

type ShareSbomFormInterface = Pick<
  ShareResourcePayload,
  'emails' | 'resourceFormat' | 'sendNotification'
>;

const mockUsersForSkeleton: MappedSharedResourceUserWithAccess[] =
  SHARED_ELEMENTS_USERS_WITH_ACCESS_STUB;

export const ShareSbomModal = ({ onClose, resourcesIds }: ShareSbomModalProps) => {
  const firstResouceId: string = resourcesIds[0] || '';
  const isSingleResource = resourcesIds.length === 1;
  const { t } = useTranslation();
  const [step, setStep] = useState<'users' | 'sharingOptions'>(
    isSingleResource ? 'users' : 'sharingOptions',
  );
  const { mutateAsync: postSharedResourceUsers, isLoading } =
    usePostSharedResourceUsers();
  const {
    isFetching: isLoadingUsers,
    error: usersWithAccessError,
    data: usersWithAcces,
  } = useFetchSharedResourceUsers(firstResouceId, {
    enabled: isSingleResource,
  });
  const { mutateAsync: deleteAccess } = useDeleteSharedResourceUsers();
  const showSharingOptions =
    step === 'sharingOptions' ||
    usersWithAcces?.length === 0 ||
    (step === 'users' && Boolean(usersWithAccessError));
  const { error: showErrorNotification, success: showSuccessNotification } =
    useNotifications();
  const form = useForm<ShareSbomFormInterface>({
    initialValues: { emails: [], sendNotification: true, resourceFormat: 'json' },
    validateInputOnBlur: true,
  });

  const shareSboms = async () => {
    if (form.values.emails.length === 0) {
      onClose();
      return;
    }

    const sbomsQuantity = resourcesIds.length;
    try {
      await postSharedResourceUsers({
        sendNotification: form.values.sendNotification,
        resourceFormat: form.values.resourceFormat,
        emails: form.values.emails,
        resourcesIds,
        resourcesType: 'asset',
      });

      showSuccessNotification({
        title: `${sbomsQuantity} ${t('global.sbom', { count: sbomsQuantity })} ${t('page.assets.shareSbomModal.sharedToPortal')}`,
        message: '',
      });
    } catch (error) {
      showErrorNotification({
        title: t('global.error'),
        message: t('global.unexpectedError'),
      });

      captureMessage(`Error sharing sboms: ${sbomsQuantity}`, 'error');
      captureException(error);
    }
  };

  const removeUserAccess = async (userId: string) => {
    try {
      await deleteAccess({
        userId,
        resourceId: firstResouceId,
      });
    } catch (error) {
      showErrorNotification({
        title: t('global.error'),
        message: t('global.unexpectedError'),
      });

      captureMessage(`Error removing user access`, 'error');
      captureException(error);
    }
  };

  useEffect(() => {
    if (usersWithAccessError) {
      setStep('sharingOptions');
    }
  }, [usersWithAccessError]);

  return (
    <ManifestModal
      size="lg"
      opened
      withCloseButton={true}
      onClose={onClose}
      title={t('page.assets.shareSbomModal.title')}
      subtitle={t('page.assets.shareSbomModal.description')}
      body={
        <>
          <Flex w="100%" direction="column" gap="12px">
            <EmailInput
              placeholder={t('page.assets.shareSbomModal.emailPlaceholder')}
              id="share-sbom-emails"
              {...{
                ...form.getInputProps('emails'),
                onChange: (value) => {
                  form.getInputProps('emails').onChange(value);

                  if (value.length > 0) {
                    setStep('sharingOptions');
                  }
                },
              }}
            />
            {showSharingOptions && (
              <div>
                <Box mb="32px">
                  <Checkbox
                    label={t('page.assets.shareSbomModal.notifyByEmail')}
                    {...form.getInputProps('sendNotification')}
                    checked={form.getInputProps('sendNotification').value}
                  />
                </Box>
                <Radio.Group
                  name="sbomFormat"
                  label={
                    <span className={styles.subtitle}>
                      {t('page.assets.shareSbomModal.sbomFormat')}
                    </span>
                  }
                  withAsterisk
                  {...form.getInputProps('sbomFormat')}
                >
                  <Divider mt="12px" mb="16px" />
                  <Stack>
                    <Radio
                      disabled
                      value="json"
                      label={t('page.assets.shareSbomModal.jsonFormat.label')}
                      classNames={{
                        description: styles.radioDescription,
                      }}
                      description={t('page.assets.shareSbomModal.jsonFormat.description')}
                    />
                  </Stack>
                </Radio.Group>
              </div>
            )}
            {!showSharingOptions && (
              <div>
                <Skeleton
                  radius="md"
                  width="30%"
                  data-testid={isLoadingUsers ? 'users-skeleton' : ''}
                  visible={isLoadingUsers}
                  className={styles.skeleton}
                >
                  <span className={styles.subtitle}>
                    {t('page.assets.shareSbomModal.whoHasAccess')}
                  </span>
                </Skeleton>
                <div className={styles.emailContainer}>
                  {(usersWithAcces || mockUsersForSkeleton).map((user) => (
                    <div key={user.id}>
                      <Divider mt="12px" mb="12px" />
                      <RemovableListItem
                        isLoading={isLoadingUsers}
                        title={user.email}
                        subtitle={`${t('page.assets.shareSbomModal.sharedOn')} ${user.dateSharedText}`}
                        removeLabel={t('global.remove')}
                        onRemove={() => removeUserAccess(user.id)}
                        rigthSection={
                          <Flex align="top" justify={'center'}>
                            <AvatarComponent
                              avatarLetter={user.avatarLetter}
                              isLoading={isLoadingUsers}
                            />
                          </Flex>
                        }
                      />
                    </div>
                  ))}
                  <Divider mt="12px" />
                </div>
              </div>
            )}
          </Flex>
        </>
      }
      primaryAction={{
        label: t('global.done'),
        onClick: shareSboms,
        isLoading: isLoading,
      }}
    />
  );
};
