import { InterfaceOssIngest } from '@manifest-cyber/types/interface/dbTables';
import {
  ActionIcon,
  Alert,
  Badge,
  Button,
  Flex,
  Loader,
  Menu,
  Tabs,
  Text,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useReward } from 'react-rewards';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { MarkInactiveAssetWarningModal } from '../../components/Assets/MarkInactiveAssetWarningModal';
import ClickableRegion from '../../components/ClickableRegion';
import Icon from '../../components/Icon';
import Labels from '../../components/Labels';
import ManageLabelsModal from '../../components/Labels/ManageLabelsModal';
import Loading from '../../components/Loading';
import appConfig from '../../configs/appConfig';
import featureFlagDisciminators from '../../configs/featureFlags';
import { useCheckProductAssociation } from '../../hooks/assets/useCheckProductAssociation';
import { usePostAssets } from '../../hooks/mutations/usePostAssets';
import { usePutAssetLabels } from '../../hooks/mutations/usePutAssetLabels';
import { useFetchAsset } from '../../hooks/queries/useFetchAsset';
import { useFetchAssetVersions } from '../../hooks/queries/useFetchAssetVersions';
import { useFetchComponents } from '../../hooks/queries/useFetchComponents';
import { useFetchOrganization } from '../../hooks/queries/useFetchOrganization';
import { useFetchOssIngest } from '../../hooks/queries/useFetchOssIngest';
import { useFetchRiskiestAssetComponents } from '../../hooks/queries/useFetchRiskiestAssetComponents';
import { useFetchSBOMs } from '../../hooks/queries/useFetchSBOMs';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import normalizeAuthor from '../../lib/normalizeAuthor';
import { truncateString } from '../../lib/truncateString';
import '../../scss/pages/asset.scss';
import { ShareSbomModal } from '../Assets/ShareSbomModal/ShareSbomModal';
import NoMatch404 from '../NoMatch404';
import styles from './Asset.module.scss';
import { AssetPdf } from './AssetPdf/AssetPdf';
import { mapAssetDetail } from './mappers/assetDetail/assetDetail.mapper';
import { AssetAboutTab } from './tabs/AssetAboutTab';
import { AssetComponentsTab } from './tabs/AssetComponentsTab';
import { AssetRiskOverviewTab } from './tabs/AssetRiskOverviewTab';
import { AssetVersionsTab } from './tabs/AssetVersionsTab';
import { AssetVulnerabilitiesTab } from './tabs/AssetVulnerabilitiesTab';

const Asset = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeTab = pathname.split('/').pop();
  const { reward } = useReward('rewardId', 'confetti');
  const [viewExportDocument, setViewExportDocument] = useState<boolean>(false);
  const [exportGraphImageBase64, setExportGraphImageBase64] = useState<string>('');
  const sbomEnrichFeatureFlag = useFeatureFlag(featureFlagDisciminators.sbomEnrich);

  const { assetId, organizationId } = useParams();
  const [currentOrgId] = useOrganizationId(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [labelEditModalOpen, setLabelEditModalOpen] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [versionPagination, setVersionPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [versionSearch, setVersionSearch] = useState('');

  const getVersionSearch = () => {
    return versionSearch;
  };

  const checkProductAssociationsMutation = useCheckProductAssociation();
  const [
    openedMarkInactiveAssetWarningModal,
    {
      open: openMarkInactiveAssetWarningModal,
      close: closeMarkInactiveAssetWarningModal,
    },
  ] = useDisclosure(false);

  // Fetches
  const { data: fetchedCurrentOrganization } = useFetchOrganization({
    organizationId: currentOrgId,
  });

  const {
    data: fetchedAsset,
    isLoading: isLoadingAsset,
    isError: fetchAssetError,
  } = useFetchAsset(assetId, organizationId, {
    refetchInterval: 10_000,
    keepPreviousData: true,
  });
  const isEnabledSharingPortal = useFeatureFlag(featureFlagDisciminators.sharingPortal);
  const mappedAsset = fetchedAsset ? mapAssetDetail(fetchedAsset) : undefined;

  const { data: { data: fetchedSboms = [] } = {}, isError: fetchSbomsError } =
    useFetchSBOMs({
      assetId,
    });

  //fetch asset versions
  const {
    data: { data: fetchedAssetVersions = [], queryInfo: { countVersions = 0 } = {} } = {},
    isLoading: isLoadingVersions,
    isError: fetchVersionsError,
  } = useFetchAssetVersions({
    organizationAssetId: assetId,
    limit: versionPagination.pageSize,
    page: versionPagination.pageIndex + 1,
    sortColumn: 'dateCreated',
    sortType: 'desc',
    search: getVersionSearch(),
  });

  //pre-fetch components for components tab and get total components count
  const {
    data: { queryInfo: { totalCount: countComponents = 0 } = {} } = {},
    isLoading: isLoadingComponents,
    isError: fetchComponentsError,
  } = useFetchComponents({
    assetId,
    sortColumn: undefined,
    sortType: undefined,
    page: 1,
    limit: 20,
  });

  // Fetch Ingest data (if applicable)
  const {
    data: fetchedIngest = {} as InterfaceOssIngest,
    isLoading: isLoadingIngests,
    isError: fetchIngests,
  } = useFetchOssIngest({
    ossIngestId: `${fetchedAsset?.ossIngestId}`,
  });

  const {
    mutateAsync: putAssetLabels,
    isLoading: isAddingLabels,
    isError: isErrorAddingLabels,
  } = usePutAssetLabels();

  // Riskiest Components
  const { data: riskiestComponents, isLoading: isLoadingRiskiestComponents } =
    useFetchRiskiestAssetComponents(fetchedAsset?._id?.toString() || '', 10);
  //end Fetches

  const queryClient = useQueryClient();
  const [updateAssetError, setUpdateAssetError] = useState<boolean>(false);
  const { mutate: mutateAssets, isLoading: isLoadingPostAssets } = usePostAssets();
  const handleAssetProductAssociationCheck = (asset: {
    isActive?: boolean;
    _id?: string | null;
  }) => {
    if (localStorage.getItem('markInactiveAssetWarning') !== 'false' && asset.isActive) {
      checkProductAssociationsMutation.mutate([asset._id?.toString() as string], {
        onSuccess: (data) => {
          if (data.success && data.data) {
            openMarkInactiveAssetWarningModal();
          } else {
            toggleAssetActiveStatus(asset);
          }
        },
      });
    } else {
      toggleAssetActiveStatus(asset);
    }
  };

  const handleMarkInactiveAssetWarning = (selectedAsset: { isActive?: boolean }) => {
    closeMarkInactiveAssetWarningModal();
    toggleAssetActiveStatus(selectedAsset);
  };

  const toggleAssetActiveStatus = (asset: { isActive?: boolean }) => {
    const toggleTo = !asset?.isActive;

    mutateAssets(
      {
        assets: {
          [fetchedAsset?._id?.toString() as string]: toggleTo,
        },
      },
      {
        onSuccess: (response: { success: boolean; errors: string[]; data: [] }) => {
          if (response.errors.length) {
            setUpdateAssetError(true);
          } else {
            setUpdateAssetError(false);
            queryClient.invalidateQueries([
              'asset',
              { _id: assetId },
              `/asset/${assetId}`,
            ]);
          }
        },
      },
    );
  };

  const activeVulnerabilities = useMemo(
    () => fetchedAsset?.countVulnerabilities?.total || 0,
    [fetchedAsset],
  );

  useEffect(() => {
    if ((activeVulnerabilities ?? 1) < 1) {
      reward();
    }
  }, [activeVulnerabilities]);

  const onOpenShareModal = () => {
    setOpenShareModal(true);
  };

  if (isLoadingAsset) {
    return (
      <>
        <Loading />
      </>
    );
  }

  if (!isLoadingAsset && !fetchedAsset) {
    return <NoMatch404 />;
  }

  let assetAuthor = normalizeAuthor(fetchedAsset, t)?.toUpperCase() || null;
  if (!assetAuthor || assetAuthor?.length < 1) {
    assetAuthor = null;
  }

  return (
    <section className="page-asset-details">
      <Helmet title={t('page.assets.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      {errors.length > 0 ||
        fetchAssetError ||
        fetchSbomsError ||
        fetchVersionsError ||
        (fetchComponentsError && (
          <ul className="page-errors anim-slideInUpShort">
            {errors.map((error) => (
              <li>{error}</li>
            ))}
            {fetchAssetError && <li>Unable to fetch asset</li>}
            {fetchSbomsError && <li>Unable to fetch asset SBOMs</li>}
            {fetchVersionsError && <li>Unable to fetch asset versions</li>}
            {fetchComponentsError && <li>Unable to fetch asset components</li>}
          </ul>
        ))}

      {updateAssetError ? (
        <Alert
          variant="light"
          color="red"
          icon={<Icon icon={'exclamation-triangle'} />}
          mb={20}
          withCloseButton
        >
          {t('page.asset.assetUpdateError')}
        </Alert>
      ) : null}

      <div className="asset-header">
        <div className="main-header-content">
          <div className="asset-header anim-slideInDownShort">
            {fetchedIngest && fetchedIngest?._id && (
              <div className="supplier-type">
                {fetchedIngest?.metadata?.groupName &&
                  `${fetchedIngest?.metadata?.groupName?.toUpperCase()}`}
                {fetchedIngest?.metadata?.groupName && (
                  <span className="supplier-divider">|</span>
                )}
                {`${t('page.asset.open-source')?.toUpperCase()}`}
                <>
                  <span className="supplier-divider">|</span>
                  <Flex gap={8} align={'center'}>
                    <div
                      className={
                        fetchedAsset?.isActive != false
                          ? 'asset-active'
                          : 'asset-inactive'
                      }
                    ></div>
                    <span>
                      {fetchedAsset?.isActive != false
                        ? t('global.active').toUpperCase()
                        : t('global.inactive').toUpperCase()}
                    </span>
                  </Flex>
                </>
              </div>
            )}
            {(!fetchedIngest || !fetchedIngest?._id) && (
              <div className="supplier-type">
                {assetAuthor && `${assetAuthor}`}
                {assetAuthor && fetchedAsset?.type && (
                  <span className="supplier-divider">|</span>
                )}
                {fetchedAsset?.type && `${fetchedAsset?.type?.toUpperCase()}`}
                <>
                  <span className="supplier-divider">|</span>
                  <Flex gap={8} align={'center'}>
                    <div
                      className={
                        fetchedAsset?.isActive != false
                          ? 'asset-active'
                          : 'asset-inactive'
                      }
                    ></div>
                    <span>
                      {fetchedAsset?.isActive != false
                        ? t('global.active').toUpperCase()
                        : t('global.inactive').toUpperCase()}
                    </span>
                  </Flex>
                </>
              </div>
            )}
            <h1 title={fetchedAsset?.name}>{fetchedAsset?.name}</h1>
            <div className="asset-details">
              {fetchedAsset?.version && (
                <ClickableRegion
                  regionLabel={t('page.asset.current-version')}
                  className="current-version"
                  onClick={() => navigate('versions')} //uncomment when asset grouping is ready
                >
                  <strong>{truncateString(fetchedAsset.version, 18)}</strong>
                </ClickableRegion>
              )}
              {fetchedAsset?.dateModified && (
                <div className="asset-last-updated anim-slideInUpShort">
                  <Icon icon="calendar-arrow-up" />
                  {t('page.asset.uploaded-timestamp', {
                    timestamp: DateTime.fromISO(
                      `${fetchedAsset?.dateCreated}`,
                    ).toLocaleString(DateTime.DATE_MED),
                  })}
                </div>
              )}
              <div className="asset-tags">
                <Tooltip label={t('page.asset.labels-description')}>
                  <span className="tag-icon">
                    <Icon icon="tag" />
                  </span>
                </Tooltip>
                <Labels
                  entity={fetchedAsset}
                  condensed
                  onEditButtonClick={() => setLabelEditModalOpen(true)}
                  rounded
                />
              </div>
            </div>
          </div>
        </div>
        <div className="secondary-header-content">
          {fetchedAsset && (
            <Flex gap={8} align={'center'}>
              <Menu position="bottom-end">
                <Menu.Target>
                  <Button
                    className="manifest-menu__target"
                    rightIcon={<Icon icon="chevron-down" />}
                    leftIcon={<Icon icon="arrow-down-to-bracket" />}
                    variant="default"
                  >
                    {t('global.download')}
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => setViewExportDocument(true)}
                    icon={<Icon icon="arrow-down-to-bracket" />}
                    className="manifest-menu__item"
                  >
                    <span>{t('page.asset.download-asset-report')}</span>
                  </Menu.Item>
                  {fetchedSboms && fetchedSboms.length > 0 ? (
                    <>
                      {fetchedAsset.changeMade ? (
                        <Menu.Item
                          component="a"
                          href={`//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${fetchedSboms[0]?._id}?redirect=1&editedSbom=true`}
                          icon={<Icon icon="arrow-down-to-bracket" />}
                          className="manifest-menu__item"
                        >
                          <span>{t('page.asset.download-edited-sbom')}</span>
                        </Menu.Item>
                      ) : null}

                      <Menu.Item
                        component="a"
                        href={`//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${fetchedSboms[0]?._id}?redirect=1&originalSbom=true`}
                        icon={<Icon icon="arrow-down-to-bracket" />}
                        className="manifest-menu__item"
                      >
                        <span>
                          {t(
                            fetchedAsset.changeMade
                              ? 'page.asset.download-original-sbom'
                              : 'page.assets.details.download-sbom',
                          )}
                        </span>
                      </Menu.Item>
                    </>
                  ) : (
                    <Menu.Item
                      disabled
                      icon={<Icon icon="down-to-bracket" />}
                      className="manifest-menu__item"
                    >
                      <span>{t('page.asset.no-download-sbom')}</span>
                    </Menu.Item>
                  )}
                  {fetchedAsset.countVulnerabilities ? (
                    <Menu.Item
                      component="a"
                      href={`//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/asset/exportVulnerability?redirect=1&organizationAssetId=${fetchedAsset._id}`}
                      icon={<Icon icon="arrow-down-to-bracket" />}
                      className="manifest-menu__item"
                    >
                      <span>{t('page.asset.download-vdr')}</span>
                    </Menu.Item>
                  ) : (
                    <Tooltip label={t('page.asset.download-vdr-no-vulns')}>
                      <div className="tooltip-wrapper--disabled-children">
                        <Menu.Item
                          icon={<Icon icon="arrow-down-to-bracket" />}
                          disabled
                          className="manifest-menu__item"
                        >
                          <span>{t('page.asset.download-vdr')}</span>
                        </Menu.Item>
                      </div>
                    </Tooltip>
                  )}
                </Menu.Dropdown>
              </Menu>
              {mappedAsset && isEnabledSharingPortal && (
                <>
                  {mappedAsset.shareInformation.isShared && (
                    <Tooltip
                      label={t('page.asset.sharedWith', {
                        count: mappedAsset.shareInformation.sharingCount,
                      })}
                    >
                      <Button
                        variant="default"
                        leftIcon={<Icon icon="user-plus" />}
                        onClick={onOpenShareModal}
                      >
                        {t('global.share')}
                      </Button>
                    </Tooltip>
                  )}
                  {!mappedAsset.shareInformation.isShared && (
                    <Button
                      variant="default"
                      leftIcon={<Icon icon="lock" />}
                      onClick={onOpenShareModal}
                    >
                      {t('global.share')}
                    </Button>
                  )}
                </>
              )}

              <Menu withinPortal position="bottom-end">
                <Menu.Target>
                  <ActionIcon
                    variant="filled"
                    className={styles.actionsMenu}
                    size="lg"
                    aria-label={t('global.edit')}
                    disabled={isLoadingPostAssets}
                  >
                    <Icon icon="ellipsis-vertical" size="lg" />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => {
                      handleAssetProductAssociationCheck(fetchedAsset);
                    }}
                    icon={
                      <Icon
                        icon={fetchedAsset?.isActive != false ? 'eye-slash' : 'eye'}
                        size="sm"
                      />
                    }
                  >
                    {t(
                      fetchedAsset?.isActive != false
                        ? 'page.asset.markAsInactive'
                        : 'page.asset.markAsActive',
                    )}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Flex>
          )}
        </div>
      </div>
      <Tabs
        value={activeTab}
        onTabChange={(newTab) => navigate(newTab as string, { replace: true })}
      >
        <Tabs.List>
          <Tabs.Tab value="overview">{t('page.asset.tabs.risk-overview')}</Tabs.Tab>
          <Tabs.Tab value="vulnerabilities">
            {t('page.asset.tabs.vulnerabilities')}
            <Badge id="rewardId">{activeVulnerabilities?.toLocaleString()}</Badge>
          </Tabs.Tab>
          <Tabs.Tab value="components">
            {t('page.asset.tabs.components')}
            <Badge>
              {isLoadingComponents ? (
                <Loader color="white" size={'xs'} />
              ) : (
                countComponents?.toLocaleString()
              )}
            </Badge>
          </Tabs.Tab>
          <Tabs.Tab value="versions">
            {t('page.asset.tabs.versions')}
            <Badge id="rewardId">
              {isLoadingVersions ? (
                <Loader color="white" size={'xs'} variant="dots" />
              ) : (
                countVersions?.toLocaleString()
              )}
            </Badge>
          </Tabs.Tab>
          <Tabs.Tab value="about">{t('page.asset.tabs.about')}</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <ManageLabelsModal
        putLabels={putAssetLabels}
        entityType="asset"
        labelIds={{
          [fetchedAsset._id?.toString()!]:
            fetchedAsset.labelIds?.map((l) => l.toString()) || [],
        }}
        open={labelEditModalOpen}
        onClose={() => setLabelEditModalOpen(false)}
      />
      <MarkInactiveAssetWarningModal
        isOpen={openedMarkInactiveAssetWarningModal}
        close={() => closeMarkInactiveAssetWarningModal()}
        confirm={() => {
          handleMarkInactiveAssetWarning(fetchedAsset);
        }}
        count={1}
      />
      <AssetPdf
        assetId={assetId!}
        organizationId={organizationId!}
        viewExportDocument={viewExportDocument}
        setViewExportDocument={setViewExportDocument}
      />
      <Routes>
        <Route
          path="overview"
          element={
            <AssetRiskOverviewTab
              fetchedAsset={fetchedAsset}
              riskiestComponents={riskiestComponents || []}
              isLoadingRiskiestComponents={isLoadingRiskiestComponents}
            />
          }
        />
        <Route
          path="vulnerabilities"
          element={
            <AssetVulnerabilitiesTab activeVulnerabilities={activeVulnerabilities} />
          }
        />
        <Route path="components" element={<AssetComponentsTab />} />
        <Route
          path="versions"
          element={
            <AssetVersionsTab
              versions={fetchedAssetVersions}
              isLoading={isLoadingVersions}
              onChangeSearchValue={setVersionSearch}
              currentOrganizationAssetId={`${fetchedAsset?._id}`}
              pagination={{
                currentPage: versionPagination?.pageIndex,
                limitPerPage: versionPagination?.pageSize,
                totalResults: countVersions,
                onChangePage: setVersionPagination, // Function to fire
              }}
            />
          }
        />
        <Route
          path="about"
          element={
            <AssetAboutTab
              fetchedIngest={fetchedIngest as InterfaceOssIngest}
              fetchedAsset={fetchedAsset}
              fetchedSboms={fetchedSboms}
              setLabelEditModalOpen={setLabelEditModalOpen}
              isEnrichedSBOM={
                fetchedCurrentOrganization?.defaultSettings
                  ?.enabledSbomEnrichmentSource === 'PARLAY'
              }
            />
          }
        />
        <Route path="*" element={<Navigate to="overview" replace />} />
      </Routes>
      {sbomEnrichFeatureFlag &&
      fetchedCurrentOrganization?.defaultSettings?.enabledSbomEnrichmentSource ===
        'PARLAY' ? (
        <Text className={styles.enrichmentDisclaimer}>
          <sup>*</sup>
          {t('page.uploadSettings.enrichedSBOMDisclaimer')}
        </Text>
      ) : null}
      {mappedAsset?._id && openShareModal && (
        <ShareSbomModal
          onClose={() => {
            setOpenShareModal(false);
          }}
          resourcesIds={[mappedAsset._id]}
        />
      )}
    </section>
  );
};

export default Asset;
