import { InterfaceSbom } from '@manifest-cyber/types/interface/dbTables';
import hitApi from '.';
import { APIResponse, APIResponseWithQueryInfo } from '../types/api_response.model';

export type MergeSBOMsResponse = {
  mergedSbom: string;
  sbomId: string;
};

export type SBOMTargetStatus = 'successful' | 'pending' | 'failed';

export type FetchSBOMsParams = {
  assetId?: string;
  limit?: number;
  page?: number;
  searchText?: string;
  sortColumn?: string;
  sortType?: string;
  targetStatus?: SBOMTargetStatus;
};

export const getSBOMs = async (
  params: FetchSBOMsParams,
): Promise<APIResponseWithQueryInfo<InterfaceSbom[]>> => {
  const { assetId, limit, sortColumn, sortType, page, searchText, targetStatus } = params;
  let fetchUrl = `/sboms?`;
  if (assetId) {
    fetchUrl += `&assetId=${assetId}`;
  }
  if (limit) {
    fetchUrl += `&limit=${limit}`;
  }
  if (sortColumn && sortType) {
    fetchUrl += `&sort={"${sortColumn}":${sortType === 'asc' ? 1 : -1}}`;
  }
  if (page) {
    fetchUrl += `&page=${page}`;
  }
  if (searchText) {
    fetchUrl += `&searchText=${searchText}`;
  }
  if (targetStatus) {
    fetchUrl += `&targetStatus=${targetStatus}`;
  }
  fetchUrl.replace('?&', '?');

  const response = (await hitApi.get(fetchUrl)) as APIResponseWithQueryInfo<
    InterfaceSbom[]
  >;
  return response;
};

export const getMergedSBOMs = async (sbomIDs: string[]) => {
  const response = await hitApi.get('sboms/multiple-sbom-return', true, {
    merge: true,
    sbomIds: sbomIDs.join(',') || '',
  });
  return response as APIResponse<MergeSBOMsResponse[]>;
};
