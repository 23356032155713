import axios from 'axios';

import packageJson from '../../package.json' assert { type: 'json' };
import buildJson from './meta.json' assert { type: 'json' };

const apiVersion = '1';
const currentEnv = import.meta.env.MODE || 'production';
const appEnv = buildJson.APP_ENV || currentEnv;

console.info(`AppConfig - Configured for ${appEnv} v${packageJson.version}!`);

const appConfigDefaults = {
  local: {
    apiUrl: 'local.manifestcyber.com:8081',
    apiVersion,
    contactEmail: '',
    cspIncludeSample: true, // Where applicable, include samples of infringing content in CSP reports
    cspReportOnly: true, // Enable report-only mode for CSP, which disables protections in favor of reporting
    cspWildcardWhitelist: ['img-src', 'media-src'], // Wildcard whitelist (*) `img-src` and `media-src` in CSP
    envLabel: 'Local',
    fbTrackingID: '', // FB tracking ID
    githubAppUrl: 'https://github.com/apps/manifest-cyber-dev',
    googleAnalyticsID: '', // Google Analytics ID
    googleAnalyticsId4: '', // Google Analytics v4 ID
    googleTagID: '', // Google Analytics Tagging ID
    isSentryEnabled: false,
    sentrySampleRate: 1.0,
    siteURL: 'local.manifestcyber.com:3000',
    cookieDomain: 'local.manifestcyber.com',
    orySDKUrl: 'http://local.manifestcyber.com:4433',
    useSsl: false,
  },
  development: {
    apiUrl: 'api-dev.manifestcyber.com',
    apiVersion,
    contactEmail: '',
    cspIncludeSample: true, // Where applicable, include samples of infringing content in CSP reports
    cspReportOnly: true, // Enable report-only mode for CSP, which disables protections in favor of reporting
    cspWildcardWhitelist: ['img-src', 'media-src'], // Wildcard whitelist (*) `img-src` and `media-src` in CSP
    envLabel: 'Development',
    fbTrackingID: '', // FB tracking ID
    githubAppUrl: 'https://github.com/apps/manifest-cyber-dev',
    googleAnalyticsId: '', // Google Analytics ID (UA-XXXXXX)
    googleAnalyticsId4: '', // Google Analytics v4 ID
    googleTagID: '', // Google Analytics Tagging ID
    isSentryEnabled: true,
    sentrySampleRate: 1.0,
    siteURL: 'app-dev.manifestcyber.com',
    cookieDomain: 'manifestcyber.com',
    orySDKUrl: 'https://auth-dev.manifestcyber.com',
    useSsl: true,
  },
  production: {
    apiUrl: 'api.manifestcyber.com',
    apiVersion,
    contactEmail: '',
    cspIncludeSample: true, // Where applicable, include samples of infringing content in CSP reports
    cspReportOnly: true, // Enable report-only mode for CSP, which disables protections in favor of reporting
    cspWildcardWhitelist: ['img-src', 'media-src'], // Wildcard whitelist (*) `img-src` and `media-src` in CSP
    envLabel: 'Production',
    fbTrackingID: '', // FB tracking ID
    githubAppUrl: 'https://github.com/apps/manifest-cyber',
    googleAnalyticsID: '', // Google Analytics ID
    googleAnalyticsId4: '', // Google Analytics v4 ID
    googleTagID: '', // Google Analytics Tagging ID
    isSentryEnabled: true,
    sentrySampleRate: 0.2,
    siteURL: 'app.manifestcyber.com',
    cookieDomain: 'manifestcyber.com',
    orySDKUrl: 'https://auth.manifestcyber.com',
    useSsl: true,
  },
  stage: {
    apiUrl: 'api-stage.manifestcyber.com',
    apiVersion,
    contactEmail: '',
    cspIncludeSample: true, // Where applicable, include samples of infringing content in CSP reports
    cspReportOnly: true, // Enable report-only mode for CSP, which disables protections in favor of reporting
    cspWildcardWhitelist: ['img-src', 'media-src'], // Wildcard whitelist (*) `img-src` and `media-src` in CSP
    envLabel: 'Stage',
    fbTrackingID: '', // FB tracking ID
    githubAppUrl: 'https://github.com/apps/manifest-cyber-dev',
    googleAnalyticsID: '', // Google Analytics ID
    googleAnalyticsId4: '', // Google Analytics v4 ID
    googleTagID: '', // Google Analytics Tagging ID
    isSentryEnabled: true,
    sentrySampleRate: 0.5,
    siteURL: 'app-stage.manifestcyber.com',
    cookieDomain: 'manifestcyber.com',
    orySDKUrl: 'https://auth-stage.manifestcyber.com',
    useSsl: true,
  },
  // Vars not specific to an environment
  appEnv,
  currentEnv,
  currentVersion: packageJson.version,
  sentryDSN:
    'https://4c25b28fbf734d19a3d655b7a50bc616@o4503966059528192.ingest.sentry.io/4504070480199680', // https://foo@bar.ingest.sentry.io/blah,
  intercomDisableTours: false,
  intercomWorkspaceId: 'eh0dl3qc',
  localStorageStore: 'gb',
  localStorageJwt: 'manifestcyberuser',
  localStorageVersion: 'manifestcyberversion',
  supportEmail: 'help@manifestcyber.com',
  helpCenterUrl: 'support.manifestcyber.com',
  faIconStyle: 'far', // Default FontAwesome6 icon style to use: fal|far|fas|fad|fass
};

// Copy defaults, and move current environment into main config
let appConfig = {
  ...appConfigDefaults,
  ...appConfigDefaults[appEnv],
  ...(buildJson?.default || buildJson || {}),
};

// Overwrite the above defaults with a `local.json` file in this dir or main dir
try {
  const localConfigFetch = await axios.get('/local.json');
  if (localConfigFetch && localConfigFetch?.data) {
    console.info('local config file found!');
    appConfig = {
      ...appConfig,
      ...localConfigFetch?.data,
    };
  }
} catch (err) {
  /* Ignore */
  console.error('Error reading local.json file:', err.message);
}

export default appConfig;
