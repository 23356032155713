import { Flex } from '@mantine/core';
import ClickableManifestLogo from '../../../components/Logo';
import styles from './PortalFooter.module.scss';

export const PortalFooter = ({
  link,
  description,
}: {
  link: string;
  description: string;
}) => {
  return (
    <div className={styles.footer}>
      <Flex gap="12px" justify={'center'} bottom={0} pt="20px" pb="32px">
        <span>{description}</span>
        <ClickableManifestLogo wordmark width={82} height={20} link={link} />
      </Flex>
    </div>
  );
};
