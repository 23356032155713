import { MappedSharedResourceUserWithAccess } from '../api/shareResource/shareResource.api';

export const SHARED_ELEMENTS_USERS_WITH_ACCESS_STUB: MappedSharedResourceUserWithAccess[] =
  [
    {
      avatarLetter: 'J',
      dateSharedText: 'March 17, 2024',
      email: 'jackson.graham@example.com',
      id: '1',
    },
    {
      avatarLetter: 'M',
      dateSharedText: 'March 19, 2024',
      email: 'mike.graham@example.com',
      id: '2',
    },
    {
      avatarLetter: 'D',
      dateSharedText: 'March 27, 2024',
      email: 'daniel.graham@example.com',
      id: '3',
    },
  ];
