import { FileType, SbomFormat } from '@manifest-cyber/types/interface/db';
import { InterfaceSbom } from '@manifest-cyber/types/interface/dbTables';

export interface SBOMSelectionData {
  id: string;
  fileType: FileType;
  format: SbomFormat | null;
  formatVersion: string | null;
}

export const fromSBOMToSelectionData = (s: InterfaceSbom): SBOMSelectionData => {
  return {
    id: `${s._id}`,
    fileType: s.fileType || 'SBOM', // the same fall-back that we are using on table
    format: s.format || null,
    formatVersion: s.formatVersion || null,
  };
};
