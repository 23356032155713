import { Button } from '@mantine/core';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ClickableRegion from '../../components/ClickableRegion';
import Icon from '../../components/Icon/Icon';
import featureFlagDisciminators from '../../configs/featureFlags';
import { useFetchOrganization } from '../../hooks/queries/useFetchOrganization';
import { useAuth } from '../../hooks/useAuth';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import '../../scss/pages/userSettings.scss';
import FeatureToggle from '../FeatureToggle';
import ApiTokens from './ApiTokens/ApiTokens';
import Integrations from './Integrations';
import { AddIntegration } from './integrations/AddIntegration';
import { GitHubIntegration } from './integrations/github/GitHubIntegration';
import {
  getJiraIntegration,
  getLinearIntegration,
  getServiceNowIntegration,
} from './integrations/integrations-data';
import { RemoveIntegration } from './integrations/RemoveIntegration';
import Labels from './Labels/Labels';
import Licenses from './Licenses';
import Notifications from './Notifications/Notifications';
import OrganizationUsers from './OrganizationUsers';
import { Profile } from './Profile/Profile';
import AlertCreate from './SbomUploadAlerts/pages/AlertCreate/AlertCreate';
import SbomAlertDetail from './SbomUploadAlerts/pages/AlertDetail/AlertDetail';
import SbomUploadAlertEdit from './SbomUploadAlerts/pages/AlertEdit/AlertEdit';
import SbomUploadAlerts from './SbomUploadAlerts/pages/AlertList/AlertsList';
import Sla from './Sla';
import SubOrganizations from './SubOrganizations';
import UploadSettings from './UploadSettings';

function UserSettings() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const [currentOrgId] = useOrganizationId(null);
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });

  const showSla =
    !isLoadingCurrentOrganization &&
    fetchedCurrentOrganization?.enabledFeatures?.some((feature) => feature === 'slaV1');

  const sbomUploadAlertsFlag = useFeatureFlag('sbomUploadAlerts');
  const sbomEnrichFlag = useFeatureFlag(featureFlagDisciminators.sbomEnrich);

  const nav: {
    id: string;
    title: string;
    icon: JSX.Element;
    items: {
      id: string;
      name: string;
      component?: JSX.Element;
      /**
       * Require user to be an org admin
       */
      requireOrgAdmin?: boolean;
      /**
       * Require that current org be a parent organization
       */
      requireParentOrg?: boolean;
      /**
       * Require user to be a Manifest user
       */
      requireManifest?: boolean;
      /**
       * Require a feature flag to be enabled.
       */
      requireFeatureFlag?: string;
    }[];
  }[] = [
    {
      id: 'organization',
      title: t('global.organization'),
      icon: <Icon icon="user" />,
      items: [
        {
          id: 'apiTokens',
          name: t('page.userSettings.navItems.apiTokens'),
        },
        {
          id: 'integrations',
          name: t('page.userSettings.navItems.integrations'),
        },
        {
          id: 'labels',
          name: t('page.userSettings.navItems.labels'),
          component: <Labels />,
        },
        {
          id: 'licenses',
          name: t('page.userSettings.navItems.licenses'),
        },
        {
          id: 'people',
          name: t('page.userSettings.navItems.users'),
          requireOrgAdmin: true,
        },
        {
          id: 'sbom-upload-alerts',
          name: t('page.userSettings.navItems.sbomUploadAlerts'),
          requireFeatureFlag: 'sbomUploadAlerts',
        },
        ...(showSla
          ? [
              {
                id: 'sla',
                name: t('page.userSettings.navItems.sla'),
              },
            ]
          : []),
        {
          id: 'suborganizations',
          name: t('page.userSettings.navItems.suborganizations'),
          requireOrgAdmin: true,
          requireParentOrg: true,
        },
        ...(sbomEnrichFlag
          ? [
              {
                id: 'upload-settings',
                name: t('page.userSettings.navItems.uploadSettings'),
              },
            ]
          : []),
      ],
    },
    {
      id: 'account',
      title: t('global.account'),
      icon: <Icon icon="user" />,
      items: [
        {
          id: 'profile',
          name: t('page.userSettings.navItems.profile'),
        },
        {
          id: 'notifications',
          name: t('page.userSettings.navItems.notifications'),
        },
        {
          id: 'feature-toggle',
          name: t('page.userSettings.navItems.feature-toggle'),
          requireManifest: true,
        },
      ],
    },
  ];

  return (
    <>
      <Helmet title={t('page.userSettings.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <div className="user-settings-container">
        <div className="navigation-pane">
          <div className="pane-content">
            <div className="header">{t('navigation.settings')}</div>
            {nav.map((section) => {
              return (
                <div key={section.id} className="section">
                  <div className="section-header">
                    <div className="section-icon">{section.icon}</div>
                    <div className="section-title">{section.title}</div>
                  </div>
                  <div className="section-items">
                    {section.items.map((item) => {
                      if (item?.requireOrgAdmin) {
                        if (
                          (user?.isAdminOfOrganizations?.length < 1 ||
                            !currentOrgId ||
                            !user?.isAdminOfOrganizations?.includes(currentOrgId)) &&
                          !user?.internalRoles?.includes('staff')
                        ) {
                          return null;
                        }
                      }

                      if (item?.requireParentOrg) {
                        if (
                          user?.organizationData?.[`${currentOrgId}`]?.parent?.length > 0
                        ) {
                          return null;
                        }
                      }

                      // User must be a Manifest user
                      if (item?.requireManifest) {
                        if (
                          user?.decryptedEmails?.filter((email: string) =>
                            email?.includes('@manifestcyber.com'),
                          )?.length < 1
                        ) {
                          return null;
                        }
                      }

                      if (item?.requireFeatureFlag) {
                        const isFlagEnabled =
                          item?.requireFeatureFlag === 'sbomUploadAlerts'
                            ? sbomUploadAlertsFlag
                            : false;

                        if (!isFlagEnabled) {
                          return null;
                        }
                      }

                      const isActiveTab = pathname.includes(item.id);
                      return (
                        <ClickableRegion
                          className="section-item-clickable"
                          key={item.id}
                          href={`/settings/${item.id}`}
                        >
                          <Button
                            key={item.id}
                            className={`section-item ${isActiveTab ? 'selected' : ''}`}
                          >
                            <div className="item-title">{item.name}</div>
                          </Button>
                        </ClickableRegion>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="vertical-divider" />
        </div>

        <div className="settings-content-container">
          <div className="settings-content">
            <Routes>
              {/* Main Tabs */}
              <Route path="/apiTokens" element={<ApiTokens />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/feature-toggle" element={<FeatureToggle />} />
              <Route path="/labels" element={<Labels />} />
              <Route path="/people" element={<OrganizationUsers />} />
              <Route path="/sla" element={<Sla />} />
              <Route path="/suborganizations" element={<SubOrganizations />} />
              <Route path="/upload-settings" element={<UploadSettings />} />
              <Route path="/licenses" element={<Licenses />} />
              <Route path="/sbom-upload-alerts" element={<SbomUploadAlerts />} />

              {/* Sub Tabs */}
              <Route path="/integrations/github/*" element={<GitHubIntegration />} />

              <Route
                path="/integrations/jira/add"
                element={<AddIntegration integrationInfo={getJiraIntegration(t)} />}
              />
              <Route
                path="/integrations/jira/remove"
                element={<RemoveIntegration integrationInfo={getJiraIntegration(t)} />}
              />

              <Route
                path="/integrations/servicenow/add"
                element={<AddIntegration integrationInfo={getServiceNowIntegration(t)} />}
              />
              <Route
                path="/integrations/servicenow/remove"
                element={
                  <RemoveIntegration integrationInfo={getServiceNowIntegration(t)} />
                }
              />

              <Route
                path="/integrations/linear/add"
                element={<AddIntegration integrationInfo={getLinearIntegration(t)} />}
              />
              <Route
                path="/integrations/linear/remove"
                element={<RemoveIntegration integrationInfo={getLinearIntegration(t)} />}
              />

              <Route
                path="/sbom-upload-alerts/view/:alertId"
                element={<SbomAlertDetail />}
              />
              <Route
                path="/sbom-upload-alerts/edit/:alertId"
                element={<SbomUploadAlertEdit />}
              />
              <Route path="/sbom-upload-alerts/create" element={<AlertCreate />} />
              {/* Fallback */}
              <Route path="*" element={<Navigate to="apiTokens" replace />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserSettings;
