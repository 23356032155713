import { useQuery } from '@tanstack/react-query';
import { GetSharedSbomsParams, SharedSbomsApi } from '../../api/sharedSboms.api';

interface Options {
  enabled?: boolean;
}

export const getSharedSbomsCacheKey = ({
  limit,
  page,
  sort,
}: Parameters<typeof SharedSbomsApi.getUrl>[0]) => [
  'shared-elements',
  SharedSbomsApi.getUrl({ limit, page, sort }),
];

export const useSharedSboms = (
  { limit, page, sort, texts }: GetSharedSbomsParams,
  options?: Options,
) => {
  return useQuery({
    staleTime: 1000 * 60 * 1, // (1 min or until invalidated)
    enabled: options?.enabled,
    keepPreviousData: true,
    queryKey: getSharedSbomsCacheKey({ limit, page, sort }),
    queryFn: () => SharedSbomsApi.getElements({ limit, page, sort, texts }),
  });
};
