import type { InterfaceSbom } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { FetchSBOMsParams, getSBOMs } from '../../api/sbom.api';

export interface Options {
  enabled?: boolean;
  refetchInterval?: number;
  staleTime?: number;
  keepPreviousData?: boolean;
}

export const useFetchSBOMs = (params: FetchSBOMsParams, options?: Options) => {
  const { assetId } = params;
  return useQuery({
    queryKey: ['sboms', assetId && { assetId }, `${JSON.stringify(params)}`].filter(
      Boolean,
    ),
    queryFn: () => getSBOMs(params),
    select: (data) => ({
      data: data?.data as InterfaceSbom[],
      queryInfo: data?.queryInfo as { totalReturn: number; totalCount: number },
    }),
    onError: (error) => {
      console.error(`Unable to fetch SBOMs for asset ${assetId}: `, error);
    },
    enabled: options?.enabled,
    refetchInterval: options?.refetchInterval,
    staleTime: options?.staleTime,
    keepPreviousData: options?.keepPreviousData,
  });
};
