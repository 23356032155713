import { InterfaceSbom } from '@manifest-cyber/types/interface/dbTables';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { getSBOMs } from '../../api/sbom.api';
import {
  SBOMSelectionData,
  fromSBOMToSelectionData,
} from '../../pages/Sboms/models/SBOMSelectionData.model';
import { APIResponse } from '../../types/api_response.model';

export const useFetchSBOMsByAssetIDs = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [data, setData] = useState<SBOMSelectionData[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const client = useQueryClient();

  const fetch = async (assetIDs: string[]) => {
    try {
      if (!assetIDs.length) return;

      setIsLoading(true);
      setIsSuccess(true);
      const SBOMs: SBOMSelectionData[] = [];
      const promises: Promise<APIResponse<InterfaceSbom[]>>[] = [];

      assetIDs.forEach((assetId) => {
        // ask for cache data
        const cache = client.getQueryData<SBOMSelectionData>([`sboms_asset_${assetId}`]);
        if (cache) SBOMs.push(cache); // if cache, push into response array
        if (!cache) {
          promises.push(
            getSBOMs({ assetId, sortColumn: 'dateCreated', sortType: 'desc' }),
          );
        } // if not cache, push into promise array
      });

      const responses = await Promise.all(promises); // parallel data request

      responses.forEach((response) => {
        if (response.success && response.data.length) {
          // only pick the first, that´s why we sort by creation date
          const sbom = response.data[0];
          const sbomData = fromSBOMToSelectionData(sbom);
          SBOMs.push(sbomData);
          // once api response correctly, store data on cache
          client.setQueryData([`sboms_asset_${sbom.organizationAssetId}`], sbomData);
        }
        if (!response.success && response.errors.length) {
          console.error('Unable to fetch SBOMs: ', response.errors[0]);
          setErrors((e) => [...e, ...response.errors]);
          setIsSuccess(false);
        }
      });
      setIsLoading(false);
      setData(SBOMs);
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setData([]);
      console.error('Unable to fetch SBOMs: ', err);
      setErrors((e) => [...e, `${err}`]);
    }
  };
  return { fetch, isLoading, isSuccess, data, errors };
};
