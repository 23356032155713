import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import '../../scss/global.scss';

// Layout surrounding all authed routes
import AuthedLayout from '../../components/AuthedLayout';

// Import pages
import appConfig from '../../configs/appConfig';
import { useLastPath } from '../../hooks/utils/useLastPath';
import { useLocalStorage } from '../../hooks/utils/useLocalStorage';
import representOrganization from '../../lib/representOrganization';
import Administration from '../Administration';
import Asset from '../Asset';
import Assets from '../Assets';
import Component from '../Component';
import Components from '../Components';
import Dashboard from '../Dashboard';
import Download from '../Download';
import Login from '../Login';
import LoginVerify from '../LoginVerify';
import Logout from '../Logout';
import NoMatch404 from '../NoMatch404';
import Products from '../Products';
import SbomsPage from '../Sboms';
import UserSettings from '../UserSettings';
import VendorPortal from '../VendorPortal';
import VendorPortalThankyou from '../VendorPortal/VendorPortalThankyou';
import VendorPortalUploadCenter from '../VendorPortal/VendorPortalUploadCenter';
import Vulnerabilities from '../Vulnerabilities';
import VulnerabilityPage from '../Vulnerability';

// Import FontAwesome 6
import featureFlagDisciminators from '../../configs/featureFlags';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import '../../lib/addFontAwesomeIconLibrary';
import { PortalLayout } from '../../shared-portal/Layout/PortalLayout';
import PortalRegisterPage from '../../shared-portal/pages/PortalRegister/PortalRegister';
import SharedSboms from '../../shared-portal/pages/sharedSboms/SharedSbomsPage';
import Product from '../Product';

export default function App() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [_lastPath, setLastPath] = useLastPath();
  const [currentTheme] = useLocalStorage('manifest-theme', 'dark');
  const isSharingPortalEnabled = useFeatureFlag(featureFlagDisciminators.sharingPortal);

  useEffect(() => {
    if (pathname && !pathname.startsWith('/logout') && !pathname.startsWith('/login')) {
      setLastPath(`${pathname}?${searchParams.toString()}`);
    }
  }, [pathname]);

  useEffect(() => {
    const checkOrgId = async () => {
      const orgId = searchParams.get('organizationId');
      if (orgId) {
        await representOrganization(orgId);
      }
    };
    checkOrgId();
  }, [searchParams.get('organizationId')]);

  return (
    <div id={`manifest-app`} className={`theme-${currentTheme || 'dark'}`}>
      <Helmet
        titleTemplate={`${appConfig?.appEnv !== 'production' ? '[DEV] ' : ''}%s - ${t(
          'app.name',
        )}`}
        defaultTitle={`${appConfig?.appEnv !== 'production' ? '[DEV] ' : ''}${t(
          'app.name',
        )}`}
      >
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <Routes>
        {/* Public/Non-Protected Routes Here */}
        <Route path="/login" element={<Login />} />
        {isSharingPortalEnabled && (
          <Route path="/login/register-portal" element={<PortalRegisterPage />} />
        )}
        <Route path="/login-verify/:authToken/:authSecret" element={<LoginVerify />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/download" element={<Download />} />
        <Route path="/vendor-portal" element={<VendorPortal />} />
        <Route
          path="/vendor-portal/upload-center"
          element={<VendorPortalUploadCenter />}
        />
        <Route path="/vendor-portal/thankyou" element={<VendorPortalThankyou />} />
        {isSharingPortalEnabled && (
          <Route path="/o" element={<PortalLayout />}>
            <Route path="/o/:organization-name" element={<SharedSboms />} />
          </Route>
        )}

        {/* Protected Routes Here */}
        <Route path="/" element={<AuthedLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/administration" element={<Administration />} />
          <Route path="/asset/:assetId/*" element={<Asset />} />
          <Route path="/assets" element={<Assets />} />
          <Route path="/components" element={<Components />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:productId/*" element={<Product />} />
          <Route path="/component/:componentId/*" element={<Component />} />
          <Route path="/settings/*" element={<UserSettings />} />
          <Route path="/uploads" element={<SbomsPage />} />
          <Route path="/sboms" element={<Navigate to="/uploads" replace />} />{' '}
          {/* redirect old sboms to new uploads */}
          <Route
            path="/vulnerability/:vulnerabilityId/*"
            element={<VulnerabilityPage />}
          />
          <Route path="/vulnerabilities" element={<Vulnerabilities />} />
        </Route>

        {/* Any paths not matched above will hit here  */}
        <Route path="*" element={<NoMatch404 />} />
      </Routes>
    </div>
  );
}
